import { useHistory } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardText, Col, Row } from "reactstrap";
import { Member } from "./services/Mitglieder";
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { storage } from "./firebase";


const Mitglied :React.FC<{member: Member, backPath:string, backLable:string, onNavigateAway: (targetPath: string)=> void}> = ({member, backPath, backLable, onNavigateAway})=>{

    const [imageUrl] = useDownloadURL(
        storage.ref(member.picturePath)
    );
    
    return (<Row>

        {backPath && <Col sm="12">
            <a href="#" onClick={()=> onNavigateAway(backPath)} className="back-link"  >{`<<<  zurück / ${backLable}`}</a>
        </Col>}
        <Col sm="12">
            <Card style={{height:"290px", width:"250px", marginTop:"30px", marginBottom:"30px", cursor:"default" }} >
                <CardImg src={imageUrl} className="h-100" style={{objectFit: "cover"}} ></CardImg>
                {member.photoCopyright && <CardImgOverlay className=" h-100 p-0 d-flex flex-column justify-content-end"><CardText className=" copyright p-1"><strong> © {member.photoCopyright} </strong></CardText></CardImgOverlay>}
            </Card>
            {/* <img src={member?.pictureUrl} style={{height:"290px", width:"250px", objectFit: "cover", marginTop:"30px", marginBottom:"30px" }}></img> */}
        </Col>
        <Col sm="12">
            <h3 style={{fontWeight:700}}>{member?.name} {member?.lastname} </h3>
        </Col>

        {member.role && backPath==="/verein" &&
                <Col sm="12">
                <h5>{member?.role} </h5>
            </Col>
        
        }


        <Col sm="12">
            <div className="zitat">{member?.zitat}</div>

        </Col>
        <Col sm="12">

            <div dangerouslySetInnerHTML={{__html:member?.content}}></div>
            {member?.homepage && <p><a onClick={()=>window.open(member.homepage)} href="#">{member.homepage}</a></p>}
        </Col>




    </Row>
    
    )
}

export default Mitglied