import { Col, Container, Row } from "reactstrap"
import trennlinie from './img/line-headline.svg'
import { useCollectionDataOnce, useCollectionOnce, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import {firestore} from "./firebase";
import { Fragment } from "react";
import { StimmexText } from "./Types";





const WasIstStimmeX: React.FC<{onActiveSubsectionChanged: (activeSubsection:string)=>void} > = ({onActiveSubsectionChanged}) => {

    const [subsections] = useCollectionDataOnce<StimmexText>(firestore.collection(`/texte`).where("section", "==", "WasIstStimmex").orderBy("index", "asc"));

    const getSubsection = (s: StimmexText, i:number) => {
        return (
            <Fragment key={`section-${i}`}>
                <Col sm="12"style={i>0? {marginTop:"90px"}: {}}>
                    
                    <h1 id={`sub-${s.path}`} className="subsection"> <img src={trennlinie} className="svg-icon svg-baseline " /> {s.title}</h1>
                    
                </Col>
                <Col sm="12">
                <div dangerouslySetInnerHTML={{__html: s.content}}/>
                </Col>
            </Fragment>
        )
    }

    return (
        <Container>

            <Row>

                {subsections && subsections.map(getSubsection)}

                <Col sm="12">
                    <div className="logos" >
                        <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/images%2Fnuestart.png?alt=media&token=f77378b8-ff9d-4d20-bbe7-cf4b71eff53e"></img>
                        <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/images%2Fbundes.png?alt=media&token=62af96c1-2314-4ba5-a5dc-02c3bcfa7084"></img>


                        <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/images%2Fkum.jpg?alt=media&token=b9560d37-90db-47ce-826b-dd7df28cab85"></img>
                        <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/images%2Fdorit-alexander-otto.png?alt=media&token=c6c62da1-4dec-4700-bec8-4bbef0ff05cc" ></img>
                        <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/images%2Fzusammen.png?alt=media&token=e75fd78c-717f-476d-9837-07dee05d1604" ></img>
                    </div>
                
                </Col>

            </Row>


            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </Container>)
}

export default WasIstStimmeX;