import { MouseEventHandler } from "react";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardImg, CardTitle, Col } from "reactstrap";
import { storage } from "./firebase";



const MitgliderCard :React.FC<{picturePath:string,name:string, lastname:string, id?:string, positionList?:string }> = ({picturePath, name, lastname, id, positionList }) => {

    const history = useHistory();
    
    const onClick :MouseEventHandler<HTMLDivElement> = (e:any)=>{
        history.push(`/mitglieder/s/m/${id}`)
    }

    const [imageUrl] = useDownloadURL(
        storage.ref(picturePath)
    );


    return (

        <Col lg="4" sm="6">

            <Card onClick={onClick}>
                <CardImg top src={imageUrl}  style={{objectPosition: positionList ? positionList : "unset" }} alt="Card image cap" />
                <CardBody>
                    <CardTitle tag="h3" >{name}  {lastname}</CardTitle>
                </CardBody>

            </Card>

            

        </Col>
    )
}

export default MitgliderCard;