import React, { useEffect, useRef, useState, Fragment } from "react";
import background from "./img/background.png";
import soundmachine from "./img/soundmachine.png";
import TopTriabgle from "./TopTriangle";
import subvarLine from "./img/line-subbar-grey.svg";
import subvarLineActive from "./img/line-subbar-black.svg";
import topbarlinie from "./img/line-topbar-grey.svg";
import topbarlinieActive from "./img/line-topbar-black.svg";
import mobile, { isMobile } from "is-mobile";
import { detect } from "detect-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import stimmexLogo from "./img/logo_anim.gif";

import backgroundVid from "./img/background_vid.mp4";
import FolderSvg from "./ordner-svg";
import { getSections, Section } from "./services/Sections";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import "./App.css";
import {
  Button,
  Card,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Jumbotron,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Mitglieder from "./Mitglieder";
import SaveTheDate from "./SaveTheDate";
import WasIstStimmex from "./WasIstStimmeX";
import { usePopper } from "react-popper";
import Verein from "./Verein";
import Links from "./Links";
import SeparatorSvg from "./separator";
import { scrollToElm } from "./scrollHelper";
import MitgliedPage from "./MitgliedPage";
import SecondaryContent from "./SecondaryContent";
import { offset } from "@popperjs/core";
import SoundMachine from "./component/soundmachine";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { storage } from "./firebase";
import News from "./News";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import Festival from "./Festival";

export type BgModes = "red" | "yellow" | "turk" | "purple";

const isSafari = detect()?.name == "safari";

const App: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  // const [encodedSvgUrls, setEncodedSvgURl] = useState<{ [key: string]: string }>({});
  // const [activeContent, setActiveContent] = useState("");
  // const [doneRenderingSvgs, setdoneRenderingSvg] = useState<{ [key: string]: boolean }>({});
  const [sections, setSections] = useState<Section[]>([]);
  const history = useHistory();
  const location = useLocation();

  const [subnavbarLefts, setSubnavbarlefts] = useState<any>({});
  const [activeSubsection, setActiveSubsection] = useState("geschichte");

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  const backVideoRef = useRef<HTMLVideoElement>(null);
  const videoSourceRef = useRef<HTMLSourceElement>(null);

  const transVideoRef = useRef<HTMLVideoElement>(null);
  const transvideSourceRef = useRef<HTMLSourceElement>(null);

  const [bg_loop_yellow] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/yellow.mp4")
  );
  const [bg_loop_red] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/red.mp4")
  );
  const [bg_loop_turk] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/turk.mp4")
  );
  const [bg_loop_purple] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/purple.mp4")
  );

  const [bg_transition_red_yellow] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/red-yellow.mp4")
  );
  const [bg_transition_red_turk] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/red-turk.mp4")
  );
  const [bg_transition_red_purple] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/red-purple.mp4")
  );

  const [bg_transition_yellow_red] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/yellow-red.mp4")
  );
  const [bg_transition_yellow_turk] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/yellow-turk.mp4")
  );
  const [bg_transition_yellow_purple] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/yellow-purple.mp4")
  );

  const [bg_transition_turk_red] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/turk-red.mp4")
  );
  const [bg_transition_turk_yellow] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/turk-yellow.mp4")
  );
  const [bg_transition_turk_purple] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/turk-purple.mp4")
  );

  const [bg_transition_purple_red] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/purple-red.mp4")
  );
  const [bg_transition_purple_yellow] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/purple-yellow.mp4")
  );
  const [bg_transition_purple_turk] = useDownloadURL(
    storage.ref("assets/soundmachine/backgrounds/purple-turk.mp4")
  );

  const ref_loop_yellow = useRef<HTMLVideoElement>(null);
  const ref_loop_red = useRef<HTMLVideoElement>(null);
  const ref_loop_turk = useRef<HTMLVideoElement>(null);
  const ref_loop_purple = useRef<HTMLVideoElement>(null);

  const ref_transition_red_yellow = useRef<HTMLVideoElement>(null);
  const ref_transition_red_turk = useRef<HTMLVideoElement>(null);
  const ref_transition_red_purple = useRef<HTMLVideoElement>(null);

  const ref_transition_yellow_red = useRef<HTMLVideoElement>(null);
  const ref_transition_yellow_turk = useRef<HTMLVideoElement>(null);
  const ref_transition_yellow_purple = useRef<HTMLVideoElement>(null);

  const ref_transition_turk_red = useRef<HTMLVideoElement>(null);
  const ref_transition_turk_yellow = useRef<HTMLVideoElement>(null);
  const ref_transition_turk_purple = useRef<HTMLVideoElement>(null);

  const ref_transition_purple_red = useRef<HTMLVideoElement>(null);
  const ref_transition_purple_yellow = useRef<HTMLVideoElement>(null);
  const ref_transition_purple_turk = useRef<HTMLVideoElement>(null);

  type VideoMap = {
    [source in BgModes]: {
      [target in BgModes]: React.RefObject<HTMLVideoElement>;
    };
  };

  const videoMap: VideoMap = {
    red: {
      yellow: ref_transition_red_yellow,
      purple: ref_transition_red_purple,
      turk: ref_transition_red_turk,
      red: ref_loop_red,
    },
    yellow: {
      yellow: ref_loop_yellow,
      purple: ref_transition_yellow_purple,
      turk: ref_transition_yellow_turk,
      red: ref_transition_yellow_red,
    },
    purple: {
      yellow: ref_transition_purple_yellow,
      purple: ref_loop_purple,
      turk: ref_transition_purple_turk,
      red: ref_transition_purple_red,
    },
    turk: {
      yellow: ref_transition_turk_yellow,
      purple: ref_transition_turk_purple,
      turk: ref_loop_turk,
      red: ref_transition_turk_red,
    },
  };

  const [activeBgMode, setActiveBgMode] = useState<BgModes>("red");
  const [transitioning, setTransitioning] = useState(false);
  //const [activeBgSource, setActiveBgSource] = useState<string|undefined>();

  const currentBgSrc = useState();

  useEffect(() => {
    for (const source in videoMap) {
      const submap = videoMap[source as BgModes];
      for (const target in videoMap) {
        const video = submap[target as BgModes]?.current;
        if (video && video.readyState < 3) {
          video.load();
        }
      }
    }
  }, [
    bg_loop_red,
    bg_loop_turk,
    bg_loop_yellow,
    bg_loop_purple,
    bg_transition_red_purple,
    bg_transition_red_turk,
    bg_transition_red_yellow,
    bg_transition_turk_red,
    bg_transition_turk_purple,
    bg_transition_turk_yellow,
    bg_transition_yellow_purple,
    bg_transition_yellow_red,
    bg_transition_yellow_turk,
    bg_transition_purple_red,
    bg_transition_purple_turk,
    bg_transition_purple_yellow,
    videoMap,
  ]);

  useEffect(() => {
    if (bg_loop_red) {
      const backVideo = backVideoRef.current;
      const source = videoSourceRef.current;

      if (backVideo && source && !mobile()) {
        source.src = bg_loop_red;
        backVideo.load();
        backVideo.muted = true;
        backVideo.loop = true;
        backVideo.play();
      }
    }
  }, [bg_loop_red, backVideoRef, videoSourceRef]);

  type BgMode = {
    loopUrl: string | undefined;
    transitionUrls: { [mode in BgModes]: string | undefined };
  };

  const activeContent = location.pathname.split("/")[1];

  const secondaryActive = location.pathname.includes("/s/");

  useEffect(() => {
    getSections().then((s) => setSections(s));
  }, []);

  useEffect(() => {
    if (activeContent) {
      const subsections = sections.find(
        (s) => s.path == activeContent
      )?.subsections;
      if (subsections) {
        setActiveSubsection(subsections[0].path);
      }
    }

    setTimeout(updtaeSubnavbarLeft, 300);
  }, [activeContent, sections]);

  const navigateTo = (path: string) => {
    setOpen(false);
    history.push(path);
  };

  const setSubsectionInitial = (contentId: string) => {
    const activeContentElement = document.getElementById(
      `content-${contentId}`
    );

    if (activeContentElement) {
      activeContentElement.scrollTop = 0;
      const subsections = Array.prototype.slice.call(
        activeContentElement.querySelectorAll<HTMLElement>(".subsection")
      );
      if (subsections.length <= 1) {
        return;
      }

      const subsectionsSort = subsections
        .map((s) => {
          const pPos = activeContentElement.getBoundingClientRect();
          const cPos = s.getBoundingClientRect();
          const offset = cPos.top - pPos.top;
          return [offset, s];
        })
        .sort(([offset1, s], [offset2, s2]) => offset1 - offset2);

      const [_, targetElement] = subsectionsSort[0];

      const sectionId = (targetElement.getAttribute("id") as string).substring(
        4
      );

      if (sectionId) {
        setActiveSubsection(sectionId);
      }
    }
  };

  const onNavItemClicked = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string,
    isAbsolute: boolean = false,
    nosecondary: boolean = false,
    mobilepath?: string
  ) => {
    const useMobile = mobilepath && mobile();
    if (path === activeContent) {
      navigateTo(`/`);
    } else {
      if (!isAbsolute && !useMobile) {
        navigateTo(`/${path}`);
      } else {
        if (useMobile) {
          window.open(mobilepath, "_blank")?.focus();
        } else {
          window.open(path, "_blank")?.focus();
        }
      }
    }

    const clientRect = e.currentTarget.getBoundingClientRect();
    const newsubnavbarLefts = { ...subnavbarLefts };
    if (newsubnavbarLefts[path] !== clientRect.left) {
      newsubnavbarLefts[path] = clientRect.left;
      setSubnavbarlefts(newsubnavbarLefts);
    }

    setSubsectionInitial(path);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const updtaeSubnavbarLeft = () => {
    const newsubnavbarLefts = { ...subnavbarLefts };
    sections.forEach((s) => {
      const sectionPath = s.path;
      const clientRect = document
        .getElementById(`sec-${sectionPath}`)
        ?.getBoundingClientRect();
      //setSubsectionInitial(s.path)

      if (clientRect && newsubnavbarLefts[sectionPath] !== clientRect.left) {
        newsubnavbarLefts[sectionPath] = clientRect.left;
      }
    });

    setSubnavbarlefts(newsubnavbarLefts);
  };

  const getNavItem = (s: Section) => {
    return (
      <Fragment key={s.path}>
        {!s.subsections ? (
          <NavItem>
            <NavLink
              id={`sec-${s.path}`}
              className={s.path === activeContent ? "active" : ""}
              href="#"
              onClick={(e) =>
                onNavItemClicked(
                  e,
                  s.path,
                  s.absolute,
                  s.noSecondaryContent,
                  s.mobilePath
                )
              }
            >
              {s.label}
            </NavLink>
          </NavItem>
        ) : (
          <>
            <NavItem>
              <NavLink
                id={`sec-${s.path}`}
                className={s.path === activeContent ? "active" : ""}
                href="#"
                onClick={(e) =>
                  onNavItemClicked(
                    e,
                    s.path,
                    s.absolute,
                    s.noSecondaryContent,
                    s.mobilePath
                  )
                }
              >
                {" "}
                {s.label}
              </NavLink>
            </NavItem>
          </>
        )}

        <span className="nav-separator">
          {" "}
          <img
            src={s.path === activeContent ? topbarlinieActive : topbarlinie}
            className="svg-icon separator-main"
            style={{ height: "20px" }}
          />{" "}
        </span>
      </Fragment>
    );
  };

  const createSubsections = (s: Section) => {
    const scrollToSubsection = (sub: Section) => {
      const parent = document.getElementById(`content-${s.path}`);
      const subsection = document.getElementById(`sub-${sub.path}`);
      if (parent && subsection) {
        scrollToElm(parent, subsection, 1);
      }
    };

    return (
      <Fragment key={s.path}>
        {s.subsections && (
          <div
            className={`subnavbar ${s.path === activeContent ? "active" : ""}`}
            style={{ left: subnavbarLefts[s.path] }}
          >
            <TopTriabgle
              style={{
                display: "block",
                position: "absolute",
                top: "0",
                left: "-16px",
                width: "auto",
                height: "31px",
              }}
              opacity="1"
            />

            <Navbar color="light" light expand="lg" style={{ height: "100%" }}>
              <Nav className="mr-auto" navbar>
                {s.subsections.map((sub) => {
                  return (
                    <Fragment key={sub.path}>
                      <NavItem>
                        <NavLink
                          className={
                            sub.secondary
                              ? `${
                                  sub.path === location.pathname.substring(1)
                                    ? "active"
                                    : ""
                                }`
                              : `${
                                  sub.path === activeSubsection ? "active" : ""
                                }`
                          }
                          href="#"
                          onClick={
                            sub.absolute
                              ? () => {
                                  console.log("opening", sub.path);
                                  window.open(sub.path)?.focus();
                                }
                              : sub.secondary
                              ? () => navigateTo(`/${sub.path}`)
                              : () => scrollToSubsection(sub)
                          }
                        >
                          {" "}
                          {sub.label}
                        </NavLink>
                      </NavItem>
                      <span className="nav-separator">
                        {" "}
                        <img src={subvarLine} style={{ height: "10px" }} />{" "}
                      </span>
                    </Fragment>
                  );
                })}
              </Nav>
            </Navbar>
          </div>
        )}
      </Fragment>
    );
  };

  const toggleMobileMenu = () => {
    setOpen(!isOpen);
  };

  const onActiveSubsectionChanged = (activateSubsection: string) => {
    setActiveSubsection(activateSubsection);
  };

  const getSectionContent = (s: Section) => {
    switch (s.path) {
      case "festival":
        return <Festival />;

      case "was-ist-stimmex":
        return (
          <WasIstStimmex
            onActiveSubsectionChanged={onActiveSubsectionChanged}
          />
        );

      case "save-the-date":
        return <SaveTheDate />;

      case "mitglieder":
        return <Mitglieder />;

      case "verein":
        return <Verein />;

      case "links":
        return <Links />;
    }
  };

  const onContentScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const container = event.currentTarget;
    const subsections = Array.prototype.slice.call(
      container.querySelectorAll<HTMLElement>(".subsection")
    );
    if (subsections.length <= 1) {
      return;
    }

    const subsectionsSort = subsections
      .map((s) => {
        const pPos = container.getBoundingClientRect();
        const cPos = s.getBoundingClientRect();
        const offset = cPos.top - pPos.top;
        return [offset, s];
      })
      .sort(([offset1, s], [offset2, s2]) => offset1 - offset2);

    let firstVisibleTitleIndex = subsectionsSort.findIndex(
      ([offset, s]) => offset > 0
    );
    if (firstVisibleTitleIndex < 0) {
      firstVisibleTitleIndex = subsectionsSort.length - 1;
    }
    const [firstVisibleTitleOffset, firstVisibleTitle] =
      subsectionsSort[firstVisibleTitleIndex];
    const targetIndex =
      firstVisibleTitleOffset < container.clientHeight / 3
        ? firstVisibleTitleIndex
        : firstVisibleTitleIndex > 0
        ? firstVisibleTitleIndex - 1
        : 0;

    const [_, targetElement] = subsectionsSort[targetIndex];

    const sectionId = (targetElement.getAttribute("id") as string).substring(4);

    if (sectionId) {
      setActiveSubsection(sectionId);
    }
  };

  const getSectionContainer = (s: Section) => {
    return (
      <div
        key={s.path}
        className={`content content-shadow ${
          activeContent === s.path ? "content-active" : "content-inactive"
        } `}
      >
        <FolderSvg
          style={{
            display: "block",
            position: "absolute",
            left: "-20px",
            width: "20px",
            bottom: `${s.index * Math.min(20, 95 / sections.length) + 4.95}vh`,
          }}
          opacity="1"
        />

        <div
          onClick={() => navigateTo(`/${s.path}`)}
          className=""
          style={{
            position: "absolute",
            backgroundColor: "#FFFFFFFF",
            bottom: 0,
            left: "-20px",
            height: `${s.index * Math.min(20, 95 / sections.length) + 5}vh`,
            width: "20px",
          }}
        >
          {" "}
          <div className="rotatedText">{s.label}</div>
        </div>

        <div
          id={`content-${s.path}`}
          className="overflow-auto content-container"
          onScroll={onContentScroll}
        >
          {getSectionContent(s)}
        </div>
        <div className="right-backround"></div>
      </div>
    );
  };

  const onSoundmachineModeChange = (newMode: BgModes) => {
    const backVideo = videoMap[activeBgMode][activeBgMode].current;
    const transVideo = videoMap[activeBgMode][newMode].current;
    const newBgVideo = videoMap[newMode][newMode].current;

    if (backVideo && transVideo && newBgVideo && !mobile()) {
      setTransitioning(true);
      setActiveBgMode(newMode);

      backVideo.loop = false;
      transVideo.load();
      transVideo.currentTime = 0;
      backVideo.playbackRate = 1.8;

      backVideo.onended = (e) => {
        backVideo.onended = null;
        backVideo.playbackRate = 1;
        transVideo.classList.remove("hidden-vid");
        transVideo.classList.add("visible-vid");
        transVideo.play();

        setTimeout(() => {
          backVideo.classList.add("hidden-vid");
          backVideo.classList.remove("visible-vid");
          backVideo.currentTime = 0;

          newBgVideo.classList.remove("hidden-vid");
          newBgVideo.classList.add("visible-vid");
          newBgVideo.load();
          newBgVideo.pause();
          newBgVideo.currentTime = 0;
        }, 100);

        transVideo.onended = (e) => {
          transVideo.onended = null;
          transVideo.classList.add("hidden-vid");
          transVideo.classList.remove("visible-vid");
          newBgVideo.loop = true;
          newBgVideo.play();
          setTransitioning(false);
        };
      };
    }
  };

  return (
    <>
      <Navbar color="light" light expand="lg">
        <NavbarBrand href="#" onClick={(e) => navigateTo("/")}>
          <img src={stimmexLogo} alt="Stimme X"></img>
        </NavbarBrand>
        <NavbarToggler onClick={toggleMobileMenu} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {sections.map(getNavItem)}

            <NavItem>
              <NavLink
                id={`sec-blog`}
                className={"blog" === activeContent ? "active" : ""}
                href="#"
                onClick={(e) => onNavItemClicked(e, "s/blog")}
              >
                Blog
              </NavLink>
            </NavItem>

            <span className="nav-separator">
              {" "}
              <img
                src={"blog" === activeContent ? topbarlinieActive : topbarlinie}
                className="svg-icon separator-main"
                style={{ height: "20px" }}
              />{" "}
            </span>

            <NavItem>
              <NavLink
                id={`login`}
                href="#"
                onClick={(e) => window.open("https://stimmex-cm.web.app/")}
              >
                Login
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                id={`sec-mitglied-werden`}
                className={`mobile-menu-only ${
                  "mitglied-werden" === activeContent ? "active" : ""
                }`}
                href="#"
                onClick={(e) =>
                  onNavItemClicked(e, "mitglieder/s/mitglied-werden")
                }
              >
                <b>Mitglied werden</b>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      {sections.map(createSubsections)}

      {isSafari}

      <FontAwesomeIcon
        icon={faFacebook}
        onClick={() => window.open("https://www.facebook.com/StimmeX")}
        style={{
          position: "absolute",
          top: "7vh",
          left: "10px",
          height: "3vh",
          width: "auto",
          zIndex: 1,
          color: "white",
          cursor: "pointer",
        }}
      />
      <FontAwesomeIcon
        icon={faInstagram}
        onClick={() => window.open("https://www.instagram.com/stimme_x/")}
        style={{
          position: "absolute",
          top: "12vh",
          left: "10px",
          height: "3vh",
          width: "auto",
          zIndex: 1,
          color: "white",
          cursor: "pointer",
        }}
      />

      {isSafari && (
        <video
          id="videoBG"
          onClick={(e) => navigateTo("/")}
          autoPlay
          muted
          loop
        >
          <source src={backgroundVid} type="video/mp4" />
        </video>
      )}

      {!mobile() && !isSafari && (
        <>
          <video
            className={"videoBG "}
            src={bg_loop_red}
            preload="auto"
            ref={ref_loop_red}
            onClick={(e) => navigateTo("/")}
            autoPlay
            muted
            loop
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_loop_yellow}
            preload="auto"
            ref={ref_loop_yellow}
            onClick={(e) => navigateTo("/")}
            muted
            loop
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_loop_turk}
            preload="auto"
            ref={ref_loop_turk}
            onClick={(e) => navigateTo("/")}
            muted
            loop
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_loop_purple}
            preload="auto"
            ref={ref_loop_purple}
            onClick={(e) => navigateTo("/")}
            muted
            loop
          ></video>

          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_red_yellow}
            preload="auto"
            ref={ref_transition_red_yellow}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_red_turk}
            preload="auto"
            ref={ref_transition_red_turk}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_red_purple}
            preload="auto"
            ref={ref_transition_red_purple}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>

          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_yellow_red}
            preload="auto"
            ref={ref_transition_yellow_red}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_yellow_turk}
            preload="auto"
            ref={ref_transition_yellow_turk}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_yellow_purple}
            preload="auto"
            ref={ref_transition_yellow_purple}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>

          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_turk_yellow}
            preload="auto"
            ref={ref_transition_turk_yellow}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_turk_red}
            preload="auto"
            ref={ref_transition_turk_red}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_turk_purple}
            preload="auto"
            ref={ref_transition_turk_purple}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>

          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_purple_yellow}
            preload="auto"
            ref={ref_transition_purple_yellow}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_purple_red}
            preload="auto"
            ref={ref_transition_purple_red}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>
          <video
            className={"videoBG hidden-vid"}
            src={bg_transition_purple_turk}
            preload="auto"
            ref={ref_transition_purple_turk}
            onClick={(e) => navigateTo("/")}
            muted
          ></video>

          {/* <img src={soundmachine} className={`soundmachine ${(activeContent === "" || activeContent === "home") ? "logo-center" : "logo-left"} `} alt="soundmachine" /> */}

          <SoundMachine
            onModeChange={onSoundmachineModeChange}
            className={`soundmachine ${
              activeContent === "" ||
              activeContent === "home" ||
              activeContent === "festival"
                ? "logo-center"
                : "logo-left"
            } `}
            currentMode={activeBgMode}
            transitioning={transitioning}
          />
        </>
      )}
      {sections
        .slice()
        .reverse()
        .filter((s) => !s.noSecondaryContent)
        .map(getSectionContainer)}

      <SecondaryContent active={secondaryActive} />

      <News active={!activeContent} />

      <footer className="footer fixed-bottom">
        <Container fluid>
          <div className="float-left desktop-only">
            Stimme X Zeitgenössisches Musiktheater Norddeutschland
          </div>
          <div className="float-right">
            <ul>
              <li>
                {" "}
                <a href="#" onClick={() => navigateTo("/s/datenschutz")}>
                  Datenschutzerklärung
                </a>
              </li>
              <li>
                {" "}
                <a href="#" onClick={() => navigateTo("/s/impressum")}>
                  Impressum{" "}
                </a>{" "}
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default App;
