import { Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Container, Row } from "reactstrap";
import { Calendar,  momentLocalizer, Views } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./Calendar.css"
import moment from 'moment'
import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import React from "react";
import placeholderImg from './img/800x180.png'
import { scrollToElm } from "./scrollHelper";
import { JsxElement } from "typescript";
import { Route, Switch, useHistory } from "react-router-dom";
import Event from"./Event";
import { StimmexEvent } from "./services/Events";
import trennlinie from './img/line-headline.svg';
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "./firebase";
import firebase from "firebase/app";
import { start } from "@popperjs/core";
import EventCard from "./EventCard";




moment.updateLocale("DE-de", null)

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

interface YearElement {
    year: string
    events: StimmexEvent[]
}

const Archiv: React.FC<{setName:(s: string)=>void}> = ({setName}) => {

    
    setName("Archiv")

    const [events] = useCollectionDataOnce<StimmexEvent>(firestore.collection("/events").where("start", "<", yesterday).orderBy("start", "asc"))
    const [eventsByYear, setEventsByYear] = useState<YearElement[]>();

    useEffect(()=>{
        if(events && events.length>0){
            setAvtive(true);
            const noRepeatMap : {[title:string]: boolean}= {};
            const eventsByYear = events.reduce((acc: YearElement[], event: StimmexEvent) => {

                if(event.start instanceof firebase.firestore.Timestamp){
                    const startTimestamp = event.start as firebase.firestore.Timestamp;
                    event.start = startTimestamp.toDate();
                }
                const year = event.start.toLocaleDateString("de-DE", { year: "numeric" });
                
                let yearElement : YearElement|undefined = acc.find(e=> e.year===year);
                if(yearElement===undefined){
                    yearElement = {year: year, events:[]}
                    acc.push(yearElement)
                }
                const eventAlreadyInArchiv = noRepeatMap[event.title]
                if(!eventAlreadyInArchiv){
                    yearElement.events.push(event)
                    noRepeatMap[event.title]=true;
                }
                

                return acc;
            }, []);

            eventsByYear.forEach(ye=> ye.events.sort((e1, e2)=> e2.start- e1.start))

            setEventsByYear(eventsByYear.reverse())
        }
    }, [events])

    

    const [active, setAvtive] = useState(false);



    const getYearElements = (groupedYears: YearElement[] ) => {
        const result: JSX.Element[] = [];


        for (const yearElement of groupedYears) {
            const eventsInMonth = yearElement.events;
            const mothElement = (<YearElement name={yearElement.year} key={yearElement.year} events={eventsInMonth} />)
            result.push(mothElement)
        }
        return result;
    }

    const YearElement: React.FC<{ name: string, events: StimmexEvent[] }> = ({ name, events }) => {




        return (
            <Row>
                <Col sm="12"><h1  style={{textAlign: "center"}}>{name}</h1><br /></Col>
                {events.map(event => <EventCard event={event} key={event.id} />)}
            </Row>

        )

    }




    return (
        <>
        {active && eventsByYear &&

                <Container>

                    <Row className="events">
                        {getYearElements(eventsByYear)}
                    </Row>


                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                </Container>}

        {!active &&
            <Container>
                <Row>
                    <Col sm={12}>
                    <h1 id={`sub-save-the-date-placeholder`} className="subsection"> <img src={trennlinie} className="svg-icon " /> Archiv</h1>

                    
                    </Col>
                </Row>
            </Container>
        }

        </>

    )
}

export default Archiv

