import { MouseEventHandler } from "react";
import { Card, CardBody, CardImg, CardTitle, Col } from "reactstrap";
import { StimmexEvent } from "./services/Events";
import { useHistory } from "react-router-dom";
import { storage } from "./firebase";
import { useDownloadURL } from "react-firebase-hooks/storage";


const EventCard :React.FC<{event:StimmexEvent}> = ({event}) =>{


    const history = useHistory();

    const [imageUrl] = useDownloadURL(
        storage.ref(event.picturePath)
    );


    const onClick :MouseEventHandler<HTMLDivElement> = (e:any)=>{
        history.push(`/save-the-date/s/${event.id}`)
    }

    return (
    <Col sm="6">
        <Card id={`e-${event.id}`} onClick={onClick} >
            
            
             <CardBody>
                <CardTitle tag="h4">{event.start?.toLocaleDateString('de-DE')} {event?.start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</CardTitle>
                
                <CardTitle tag="h3" style={{fontWeight: "bold"}} >{event.title}</CardTitle>
                <CardImg key={imageUrl} src={imageUrl}  alt="Card image cap" style={{maxHeight: "250px", objectFit: "scale-down"}} /> 

                <div dangerouslySetInnerHTML={{__html: event.teaser}}></div>

             </CardBody> 


        </Card>

    </Col>)
};

export default EventCard;