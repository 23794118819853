import { MouseEventHandler } from "react";
import { Card, CardBody, CardImg, CardTitle, Col } from "reactstrap";
import { StimmexEvent } from "./services/Events";
import { useHistory } from "react-router-dom";
import { storage } from "./firebase";
import { useDownloadURL } from "react-firebase-hooks/storage";


const NewsItem :React.FC<{event:StimmexEvent}> = ({event}) =>{


    const history = useHistory();

    const [imageUrl] = useDownloadURL(
        storage.ref(event.picturePath)
    );


    const onClick :MouseEventHandler<HTMLDivElement> = (e:any)=>{
        history.push(`/save-the-date/s/${event.id}`)
    }

    return (
    <Col sm="2">
        <Card id={`e-${event.id}`} onClick={onClick}>
            
            
             <CardBody>
                <CardTitle tag="h4" style={{fontWeight: "bold"}}  className="news-item-date">{event.start?.toLocaleDateString('de-DE')}</CardTitle>
                
                
                <CardImg src={imageUrl}  alt="Card image cap" /> 


                <CardTitle tag="h6" style={{fontWeight: "bold"}} >{event.title}</CardTitle>

             </CardBody> 


        </Card>

    </Col>)
};

export default NewsItem;