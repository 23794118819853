import { useEffect, useRef } from "react";

export const useInterval = (callback:()=>void, delay:number) =>{
    const savedCallback = useRef<()=>void>();
    const clearFunction = useRef<()=>void>();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      let id = setInterval(() => {
          if(savedCallback.current){
            savedCallback.current();
          }
      }, delay);

      
      clearFunction.current = () => clearInterval(id);
      return clearFunction.current;
    }, [delay]);

    return clearFunction.current;



  }

  