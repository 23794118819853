
import { storage } from "../firebase";
import { useDownloadURL } from 'react-firebase-hooks/storage';
import React, { Fragment, SourceHTMLAttributes, useEffect, useRef, useState } from "react";
import { useInterval } from "../hooks/UseInterval";
import { BgModes as SmModes } from "../App";
import { count } from "console";
import schatten from '../img/schatten.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";



const modeMap : {[currentMode in SmModes]: Array<SmModes>} = {
    red: ["yellow", "turk", "purple"],
    yellow : ["red", "turk", "purple"],
    turk: ["red", "yellow", "purple"],
    purple: ["red", "yellow", "turk"]
}

type Position = [cx: number, cy: number];

const purpleMap : Position[][] = [
    [[60,115], [145,200], [207,168], [266, 140] ],
    [[105,95], [158,267], [217,235], [274, 206] ],
    [[150,75], [220,305], [282,273], [338, 245] ],
    [[0,0], [225,380], [290,345], [0, 0] ]
]
const getPosition: (mode: SmModes, i:number, j:number) => [cx: number, cy: number] = (mode: SmModes, i:number, j:number)=> {
    if(mode != "purple"){
        const cx = 185 + i * 28 + j * 49;
        const cy = 305 + i * 42 - j * 27;
        return [cx, cy]
    }
    return purpleMap[i][j];
}


type AudioType = "sync"|"async"|"one shot"|"alone"
type AudioDescriptor = {file:string, type: AudioType}|undefined
const audioMap : {[key in SmModes]: (AudioDescriptor)[][] } = {
    "red":[
        [undefined ,{file:"Bass", type:"sync"}, {file:"Hi Hat", type:"sync"}, {file:"Keyboard", type:"sync"}],
        [undefined ,{file:"KICK", type:"sync"}, {file:"Snare", type:"sync"}, {file:"Voice Melody", type:"sync"}],
        [undefined ,{file:"01-porno", type:"async"}, {file:"02-Michelle", type:"async"}, {file:"03 - artige", type:"async"}]
    ],
    "yellow":[
        [undefined ,{file:"Asterix", type:"one shot"}, {file:"DonaldDuck", type:"one shot"}, {file:"Goofy", type:"one shot"}],
        [undefined ,{file:"ManaMana", type:"one shot"}, {file:"PIngu", type:"one shot"}, {file:"NuPagodi", type:"one shot"}],
        [undefined ,{file:"PanicButton", type:"alone"}, {file:"DonaldDuck2", type:"one shot"}, {file:"Goofy2", type:"one shot"}]
    ],
    "turk":[
        [undefined ,{file:"Breathing slow", type:"sync"}, {file:"breathingfloor", type:"sync"}, {file:"Claps", type:"sync"}],
        [undefined ,{file:"Guitar", type:"sync"}, {file:"Tapdance", type:"sync"}, {file:"VOICE 1", type:"sync"}],
        [undefined ,{file:"VOICE 2", type:"async"}, {file:"VOICE 3", type:"async"}, {file:"VOICE 4", type:"async"}]
    ],
    "purple":[
        [undefined ,{file:"BUTTON1_Vendula", type:"alone"}, {file:"BUTTON2_Theresa", type:"alone"}, {file:"BUTTON3_Levin", type:"alone"}],
        [undefined ,{file:"BUTTON4_Michelle", type:"alone"}, {file:"BUTTON5_Lisa", type:"alone"}, {file:"BUTTON6_Kerstin", type:"alone"}],
        [undefined ,{file:"BUTTON7_Ole", type:"alone"}, {file:"BUTTON8_HansJ", type:"alone"}, {file:"BUTTON9_Dong", type:"alone"}],
        [undefined ,{file:"BUTTON10_Benjamin", type:"alone"}, {file:"BUTTON11_Faasch", type:"alone"}, undefined],
    ],
}

const SoundMachineButton: 
    React.FC<{ coordinates: [i: number, j: number], playing: boolean, onPressed : (coordinates: [i: number, j: number])=>void,
         currentMode: SmModes,  transitioning: boolean, activeStatesRef: React.MutableRefObject<{[key:string]:boolean}>,
         backSoundRef: React.RefObject<HTMLAudioElement>}> 
         
         = ({ coordinates, playing, onPressed, currentMode, transitioning, activeStatesRef, backSoundRef}) => {

    const [i, j] = coordinates;

    const [pessedButtonImgUrlRed] = useDownloadURL(
        storage.ref(`assets/soundmachine/red/button-${i}-${j}.png`)
    );

    const [pessedButtonImgUrlYellow] = useDownloadURL(
        storage.ref(`assets/soundmachine/yellow/button-${i}-${j}.png`)
    );

    const [pessedButtonImgUrlTurk] = useDownloadURL(
        storage.ref(`assets/soundmachine/turk/button-${i}-${j}.png`)
    );

    const [pessedButtonImgUrlPurple] = useDownloadURL(
        storage.ref(`assets/soundmachine/purple/button-${i}-${j}.png`)
    );




    

    
    const mode = j===0 ?  modeMap[currentMode][i] : undefined;


    const audioDescriptor = audioMap[currentMode][i][j];
    const asynchron = audioDescriptor?.type!== "sync";
    const audioUrl = `assets/sounds/${currentMode}/${audioDescriptor?.file}.mp3`
    const [audioUrlMp3] = useDownloadURL(storage.ref(audioUrl));
    

    const audioref = useRef<HTMLAudioElement>(new Audio());


    const [pushed, setPushed] = useState(false);

    const imgRef = useRef<SVGImageElement>(null)

    const [cx, cy] = getPosition(currentMode, i, j);

    const loopMap : {[key in AudioType]: boolean} = {
        "alone":false,
        "one shot":false, 
        "sync": true, 
        "async": true
    }

    useEffect(() => {
        if(!playing && !mode){
            setPushed(false);
        }


        if (audioUrlMp3) {
            audioref.current.src = audioUrlMp3;

        }

        audioref.current.muted = !pushed;

        if(audioDescriptor){
            audioref.current.loop = loopMap[audioDescriptor.type] ;
        }
            



    }, [audioUrlMp3])

    useEffect(()=>{


    },[currentMode])

    useEffect(()=>{
        if (playing) {
            if(!!audioref.current.paused && audioDescriptor?.type=="sync"){
                const t = performance.now()
                audioref.current.play();
            } 

                       
            //console.log(`started sound-${i}-${j} at ${t}`)
        }  else  {
            audioref.current.pause();
            audioref.current.muted=true;
            audioref.current.currentTime=0;
            setPushed(false);
        }
    }, [playing])

    

    const onButtonPressed = () => {
        const key = `${i}_${j}`;
        const currentActiveStates = activeStatesRef.current;
        if((currentMode=="purple" && !!!currentActiveStates[key]) && !mode){
            console.log("mole miss "+ key)
            return;
        }

        onPressed(coordinates);
        if(mode){
            return;
        }

        let loop= true;
        if(audioDescriptor){
            loop = loopMap[audioDescriptor.type] ;
        }

        if (!playing && audioUrlMp3){
            //audioref.current.src = audioUrl;
            audioref.current.muted = !pushed;
            audioref.current.loop = loop;
        }


        const newPushedState = !pushed;

        if (audioref.current) {
            audioref.current.muted = !newPushedState;
        }

        if (asynchron) {
            if (newPushedState) {
                audioref.current.play();
                
                
                if(!loop){

                    if(audioDescriptor && audioDescriptor.type == "alone"  && backSoundRef.current){
                        backSoundRef.current.muted = true;
                        if(currentMode=="purple"){
                            currentActiveStates["whacked"]= true;
                        }
                        audioref.current.onended = ()=>{
                            if(backSoundRef.current){
                                backSoundRef.current.muted = false;
                                currentActiveStates["whacked"]= false;
                            }
                            setPushed(false);
                        }
                    } else {
                        audioref.current.onended = ()=> setPushed(false);
                    }
                   
                }
                
            } else {
                audioref.current.pause();
                audioref.current.currentTime = 0;
            }

        }
        if(!mode ){
            setPushed(newPushedState);
        }
        
    }

    const getImageUrl = ()=>{
        switch(currentMode){
            case("red"):
                return pessedButtonImgUrlRed;
            case("yellow"):
                return pessedButtonImgUrlYellow;
            case("purple"):
                return pessedButtonImgUrlPurple;
            case("turk"):
                return pessedButtonImgUrlTurk;
        }
    }

    return (<>
        <image ref={imgRef} className={pushed ? "visible" : "hidden"}  style={{ pointerEvents: "none" }} width="100%" height="100%" x="0" y="0" href={getImageUrl()} xlinkHref={getImageUrl()} />
        <circle style={{ pointerEvents: "auto" }} onClick={onButtonPressed} cursor={transitioning && mode? "": "pointer"} cx={cx} cy={cy} r="20" opacity="0" fill="yellow"  >{i+" - "+j }</circle>

    </>)
}


const WhackamoleVideo : React.FC<{coordinates: [i: number, j: number],  whacked:boolean, className:string, onDeactivate : (coordinates: [i: number, j: number])=>void, count:number, activeStatesRef: React.MutableRefObject<{[key:string]:boolean}>}> = ({ whacked, className, onDeactivate, coordinates, count, activeStatesRef})=>{
    const[i,j] = coordinates;
    const [whackSrc] = useDownloadURL(storage.ref(`assets/soundmachine/purple/anim/button-${i}-${j}.webm`));
    const whackVidRef = useRef<HTMLVideoElement>(null);
    const [whackRSrc] = useDownloadURL(storage.ref(`assets/soundmachine/purple/anim/button-r-${i}-${j}.webm`));
    const whackRVidRef = useRef<HTMLVideoElement>(null);

    const activateMole = () => {
        const whackVid = whackVidRef.current;
        const whackRVid = whackRVidRef.current;
        const currentActiveStates = activeStatesRef.current;
        if(whackVid && whackRVid ){
            whackVid.playbackRate = 3;
            whackRVid.playbackRate=3;

            whackVid.classList.remove("hidden");
            whackVid.play();
            setTimeout(()=>{

                const currentActiveStates = activeStatesRef.current;

                if(currentActiveStates["whacked"]){
                    whackVid.classList.add("hidden");
                    whackRVid.classList.add("hidden")
                    return;
                }
                whackRVid.classList.remove("hidden")
                whackVid.classList.add("hidden");

                whackRVid.play();
                whackRVid.onended = ()=>{
                    whackRVid.classList.add("hidden");
                    onDeactivate(coordinates)
                }
                

            }, 800);
        }
    };

    useEffect(()=>{
        const key = `${i}_${j}`;
        const currentActiveStates = activeStatesRef.current;
        if(currentActiveStates["whacked"]){
            const whackVid = whackVidRef.current;
            const whackRVid = whackRVidRef.current;
            if(whackVid && whackRVid ){
                whackVid.classList.add("hidden");
                whackRVid.classList.add("hidden");
            }

            return;

        }
        if(!!currentActiveStates[key] ){
            activateMole();
        }
    }, [count])

    useEffect(()=>{
        [whackVidRef,whackRVidRef].forEach(vr=>{
            if(vr.current && vr.current.readyState<3){
                vr.current.load();
            }
        })

    }, [whackSrc,whackRSrc])

    return <Fragment>
        <video ref={whackVidRef} className={  "soundmachine-element  hidden"} src={whackSrc} playsInline></video>
        <video ref={whackRVidRef} className={  "soundmachine-element  hidden"} src={whackRSrc} playsInline></video>
    </Fragment>
}


const SoundMachine: React.FC<{ className: string, onModeChange :(newMode: SmModes)=>void, currentMode: SmModes, transitioning: boolean }> = ({ className, onModeChange, currentMode, transitioning }) => {

    const [internalMode, setInternalMode] = useState<SmModes>("red");

    
    const [sounmachineImgUrlRed] = useDownloadURL(
        storage.ref('assets/soundmachine/red/soundmachine-idle.png')
    );
    
    const [sounmachineImgUrlYellow] = useDownloadURL(
        storage.ref('assets/soundmachine/yellow/soundmachine-idle.png')
    );

    const [sounmachineImgUrlPurple] = useDownloadURL(
        storage.ref('assets/soundmachine/purple/soundmachine-idle.png')
    );


    const [sounmachineImgUrlTurk] = useDownloadURL(
        storage.ref('assets/soundmachine/turk/soundmachine-idle.png')
    );

    const [sounmachineImgUrl, setSounmachineImgUrl] = useState(sounmachineImgUrlRed);

    //const [activeMolesState, _setActiveMolesState] = useState<{[key:string]:boolean}>({})
    const [stopedMolesState, setStopedMolesState] = useState<{[key:string]:boolean}>({})

    const activeMolesStateRef = useRef<{[key:string]:boolean}>({})

    const setActiveMolesState = (data:{[key:string]:boolean}) => {

        activeMolesStateRef.current = data;
        //_setActiveMolesState(data);
      };

    const audioref = useRef<HTMLAudioElement>(null);
    const buttonSoundRef = useRef<HTMLAudioElement>(null);

    const svgRef = useRef<SVGSVGElement>(null)

    const urlModeMap: {[key in SmModes]: string|undefined} = {
        yellow: sounmachineImgUrlYellow,
        red: sounmachineImgUrlRed,
        purple: sounmachineImgUrlPurple,
        turk:sounmachineImgUrlTurk
    }

    useEffect(()=>{
        setSounmachineImgUrl(urlModeMap[currentMode]);
    },[sounmachineImgUrlRed])

    useEffect(()=>{
        //setSounmachineImgUrl(urlModeMap[currentMode]);
        //svgRef.current?.classList.remove("hidden");


        const transitionVideo = getTransitionVideo(internalMode, currentMode)?.current;

        // if(currentMode=="purple" && audioref && audioref.current){
        //     audioref.current.volume = 0.2;
        // }


        if(transitionVideo){
            
            transitionVideo.onended = (event)=>{
                transitionVideo.onended =null;
                setInternalMode(currentMode);
                svgRef.current?.classList.remove("hidden");
                
                
                setTimeout(()=> transitionVideo.classList.add("hidden"), 300)
                
                start();
                
                
            }
        }


    },[currentMode])








    // Generated with ffmpeg -r 10 -i %2d.png  -c:v libvpx-vp9 -b:v 2000k again.webm

    // ffmpeg -r 10 -i %2d.png  -c:v prores_ks -pix_fmt yuva444p10le again.mov
    //gs://stimmex-ec282.appspot.com/assets/soundmachine/red-purple.hevc.mp4
    // const [redYellowVidSrc] = useDownloadURL(storage.ref('assets/soundmachine/red-purple.mov'));
    const [redYellowVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/red-yellow.webm`));
    const [yellowRedVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/yellow-red.webm`));
    const [redTurkVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/red-turk.webm`));
    const [turkRedVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/turk-red.webm`));
    const [redPurpleVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/red-purple.webm`));
    const [purpleRedVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/purple-red.webm`));

    const [yellowTurkVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/yellow-turk.webm`));
    const [turkYellowVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/turk-yellow.webm`));
    const [yellowPurpleVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/yellow-purple.webm`));
    const [purpleYellowVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/purple-yellow.webm`));

    const [purpleTurkVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/purple-turk.webm`));
    const [turkPurpleVidSrc] = useDownloadURL(storage.ref(`assets/soundmachine/turk-purple.webm`));

    const redYellowVidRef = useRef<HTMLVideoElement>(null);
    const yellowRedVidRef = useRef<HTMLVideoElement>(null);
    const redTurkVidRef = useRef<HTMLVideoElement>(null);
    const turkRedVidRef = useRef<HTMLVideoElement>(null);
    const redPurpleVidRef = useRef<HTMLVideoElement>(null);
    const purpleRedVidRef = useRef<HTMLVideoElement>(null);

    const yellowTurkVidRef = useRef<HTMLVideoElement>(null);
    const turkYellowVidRef = useRef<HTMLVideoElement>(null);
    const yellowPurpleVidRef = useRef<HTMLVideoElement>(null);
    const purpleYellowdVidRef = useRef<HTMLVideoElement>(null);

    const purpleTurkVidRef = useRef<HTMLVideoElement>(null);
    const turkPurpleVidRef = useRef<HTMLVideoElement>(null);

    useEffect(()=>{
        for(const source in videoTransitionMap){
            const submap = videoTransitionMap[source as SmModes]
            for(const target in submap){
                const video = submap[target as SmModes]?.current;
                if(video){
                    video.load();
                }
            }
        }

    }, [redYellowVidSrc,yellowRedVidSrc, redTurkVidSrc, turkRedVidSrc, redPurpleVidSrc, purpleRedVidSrc,
         yellowTurkVidSrc, turkYellowVidSrc,yellowPurpleVidSrc, purpleYellowVidSrc,purpleTurkVidSrc,turkPurpleVidSrc
        ])

    const [count, setCount] = useState(0);

    const [audioUrlMp3]  = useDownloadURL(storage.ref(`assets/sounds/${currentMode}/TEPPICH.mp3`));

    const [buttonSoundUrlMp3] = useDownloadURL(storage.ref(`assets/sounds/${currentMode}/Button Click.mp3`));

    const [playing, setPlaying] = useState(false);

    const startStop = () => {
        if (audioref.current) {
            if (!playing) {
                audioref.current.play();
                setPlaying(true);
            } else {
                audioref.current.pause();
                audioref.current.currentTime = 0;
                setPlaying(false);
            }

        }

    }

    const stop = ()=> {
        if (audioref.current) {
            audioref.current.pause();
            audioref.current.currentTime = 0;
            setPlaying(false);
        }
    }

    const start = ()=> {
        if (audioref.current) {
            audioref.current.muted=false;
            audioref.current.play();
            setPlaying(true);
        }
    }

    type VideoMap = { [source in  SmModes]:  {[target in  SmModes]: React.RefObject<HTMLVideoElement>|null}}

    const videoTransitionMap : VideoMap = {
        red: {
            yellow: redYellowVidRef,
            purple: redPurpleVidRef,
            turk: redTurkVidRef,
            red: null
        },
        yellow: {
            yellow: null,
            purple: yellowPurpleVidRef,
            turk: yellowTurkVidRef,
            red: yellowRedVidRef
        },
        purple: {
            yellow: purpleYellowdVidRef,
            purple: null,
            turk: purpleTurkVidRef,
            red: purpleRedVidRef
        },
        turk: {
            yellow: turkYellowVidRef,
            purple: turkPurpleVidRef,
            turk: null,
            red:turkRedVidRef
        }

    }

    const getTransitionVideo = (currentMode: SmModes, targetMode: SmModes )=>{
        return videoTransitionMap[currentMode][targetMode];
    }

    const iOS = () => {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    const activateButton = (coordinates: [i: number, j: number])=> {
        const [i,j] = coordinates;
        const key = `${i}_${j}`;
        //const newActiveState = {... activeMolesState}
        const activeState = activeMolesStateRef.current;
        if(activeState && !!!activeState[key]){
            activeState[key]= true;
        }

        
        //setActiveMolesState(newActiveState);
        console.log("activated "+key);
    }

    const onButtonDeactivate = (coordinates: [i: number, j: number])=> {
        const [i,j] = coordinates;
        const key = `${i}_${j}`;

        const activeState = activeMolesStateRef.current;
        if(activeState){
            activeState[key]= false;
        }


        // const newActiveState = {... activeMolesState}
        // newActiveState[key]= false;
        // setActiveMolesState(newActiveState)
        console.log("deactivated  "+key);
    }

    const soundMachneButtonPressed = (coordinates: [i: number, j: number])=>{
        const [i,j] = coordinates;
        const mode = j===0?  modeMap[internalMode][i]: undefined;

        if(mode &&transitioning){
            return;
        }


        buttonSoundRef.current?.play();

        if(!playing && !mode){
            start();
        } else if(mode && mode!= internalMode) {
            onModeChange(mode);
            let  transitionVideo = getTransitionVideo(internalMode, mode)?.current;
            let src = transitionVideo?.src;
            stop();
            if(transitionVideo){
                transitionVideo.play().then(()=>{ 
                    transitionVideo?.pause();
                    setTimeout(()=>{
                        
                        if(transitionVideo ){
                            // redYellowVideo.classList.remove("vibrate");
                            // svgRef.current?.classList.remove("vibrate");

                            transitionVideo.classList.remove("hidden");
                            svgRef.current?.classList.add("hidden");
                            transitionVideo.play();
                            setSounmachineImgUrl(urlModeMap[mode]);
                            
                            //transitionVideo.onended = () => start();        
                            //redYellowVideo.classList.add("vissible-vid");
            
                        }   
                            
                    }, 500);
                
                
                });

            }  
        }

    }
    
    const getButtonRange = (currentMode:SmModes)=>{
        let range = Array<[i: number, j: number]>();
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 4; j++) {
                range.push([i, j])
            }
        }
        if(currentMode =="purple"){
            range.push([3,1])
            range.push([3,2])
        }
        return range;
    }

    const getButtonAudioRange = (currentMode:SmModes)=>{
        let range = Array<[i: number, j: number]>();
        for (let i = 0; i < 3; i++) {
            for (let j = 1; j < 4; j++) {
                range.push([i, j])
            }
        }
        if(currentMode =="purple"){
            range.push([3,1])
            range.push([3,2])
        }
        return range;
    }


    const purpleButtons  = getButtonRange("purple");
    const purpleAudioButton = getButtonAudioRange("purple")

    const getButtons = () => {
        let range = getButtonRange(currentMode);
        return range.map((c) =>{
        const key = `${c[0]}_${c[1]}`;
        //const active = !!activeMolesState[key];
        const whacked = !!stopedMolesState[key];

         return <SoundMachineButton key={key} coordinates={c} playing={playing} onPressed={soundMachneButtonPressed} currentMode={currentMode} transitioning={transitioning} activeStatesRef={activeMolesStateRef} backSoundRef={audioref} />
        })
    }
    

    const getWhackAMoleVideos = ()=>{
        return purpleButtons.map(c=>{
            const key = `${c[0]}_${c[1]}`;
            //const active = !!activeMolesState[key];
            const whacked = !!stopedMolesState[key];
            return <WhackamoleVideo onDeactivate={onButtonDeactivate} className={className} key={key} count={count} activeStatesRef={activeMolesStateRef}  whacked={whacked} coordinates={c} />
        } )
    }


    
    useInterval( () =>{
        const buttonCoordinate = purpleAudioButton[Math.floor(Math.random()*purpleAudioButton.length)];
        const[i,j] = buttonCoordinate;
        const key = `${i}_${j}`;
        if(internalMode=="purple" && !transitioning && activeMolesStateRef && !!!activeMolesStateRef.current[key] && !activeMolesStateRef.current["whacked"]){
           
            if(audioMap["purple"][i][j] ){
                activateButton(buttonCoordinate)
                setCount(count +1)
            }
            
        }
    },1500)


    return (
    <Fragment>
    <div className={ className+" vibrate"}>
        
        <svg ref={svgRef} style={{ pointerEvents: "none" }} className={" soundmachine-element "} viewBox="0 0 428 462" xmlns="http://www.w3.org/2000/svg">
            {sounmachineImgUrl && <image xlinkHref={sounmachineImgUrl} href={sounmachineImgUrl} width="100%" height="100%" x="0" y="0" />}
            {getButtons()}
        </svg>

        <video ref={redYellowVidRef} className={  "soundmachine-element hidden"}  src={redYellowVidSrc} playsInline></video>
        <video ref={yellowRedVidRef} className={  "soundmachine-element  hidden"}  src={yellowRedVidSrc} playsInline></video>

        <video ref={redPurpleVidRef} className={  "soundmachine-element  hidden"}  src={redPurpleVidSrc} playsInline></video>
        <video ref={purpleRedVidRef} className={  "soundmachine-element  hidden"}  src={purpleRedVidSrc} playsInline></video>

        <video ref={redTurkVidRef} className={  "soundmachine-element  hidden"}  src={redTurkVidSrc} playsInline></video>
        <video ref={turkRedVidRef} className={  "soundmachine-element  hidden"}  src={turkRedVidSrc} playsInline></video>

        <video ref={yellowPurpleVidRef} className={  "soundmachine-element  hidden"}  src={yellowPurpleVidSrc} playsInline></video>
        <video ref={purpleYellowdVidRef} className={  "soundmachine-element  hidden"}  src={purpleYellowVidSrc} playsInline></video>

        <video ref={yellowTurkVidRef} className={  "soundmachine-element  hidden"}  src={yellowTurkVidSrc} playsInline></video>
        <video ref={turkYellowVidRef} className={  "soundmachine-element  hidden"}  src={turkYellowVidSrc} playsInline></video>

        <video ref={turkPurpleVidRef} className={  "soundmachine-element  hidden"}  src={turkPurpleVidSrc} playsInline></video>
        <video ref={purpleTurkVidRef} className={  "soundmachine-element  hidden"}  src={purpleTurkVidSrc} playsInline></video>

        {/* Whackamole */
            !transitioning && currentMode=="purple" && getWhackAMoleVideos()

        }

        <audio style={{ display: "none" }} ref={audioref}  src={audioUrlMp3}  preload="auto" loop={true}>

        </audio>

        <audio style={{ display: "none" }} src={buttonSoundUrlMp3} ref={buttonSoundRef}  preload="auto"  loop={false}>
            
        </audio>
        
    </div>

    {playing && <FontAwesomeIcon icon={faVolumeMute} onClick={startStop} style={{position:"absolute", "top":"17vh", "left": "10px", height:"3vh", width:"auto", zIndex:1, color:"white", cursor:"pointer"}}/>}

    <img className="schatten"src={schatten}></img>
    </Fragment>
    )

}

export default SoundMachine;


