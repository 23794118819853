import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDb17IkiTJp8Jx6QtbkF9zS-QZorz_G4hY",
    authDomain: "stimmex-ec282.firebaseapp.com",
    projectId: "stimmex-ec282",
    storageBucket: "stimmex-ec282.appspot.com",
    messagingSenderId: "306278989402",
    appId: "1:306278989402:web:4bf4a5c75bcdb83d776263",
    measurementId: "G-PECZ3NDQBD"
  };

// const firebaseConfig = {
//   apiKey: "AIzaSyAjH6KcFD0J_Uk46qGs5uGyaNmPh3yaDZw",
//   authDomain: "csm-test-stimmex.firebaseapp.com",
//   projectId: "csm-test-stimmex",
//   storageBucket: "csm-test-stimmex.appspot.com",
//   messagingSenderId: "668694496753",
//   appId: "1:668694496753:web:fefbb4be7196cfd3eaf050"
// };


firebase.initializeApp(firebaseConfig);


export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();