import { Col, Container, Row } from "reactstrap";


const Impressum : React.FC<{setName: (name:string)=>void}> = ({setName})=>{

    setName("Impressum")

    return(

        <Container>
        <Row>
          <Col sm={12}>
            <h1>Impressum</h1>
            <h2>Angaben gemäß § 5 TMG:</h2>
            <h3>Postanschrift:</h3>
            <p>Stimme X Nováková Kapp GbR<br /></p>
            <p>c/o Abbildungszentrum<br /></p>
            <p>Arnoldstr. 26 - 30<br />22769 Hamburg<br /></p>
            <h3>Kontakt:</h3>
            <p>Telefon: +49 163 3114215<br />E-Mail: kontakt@stimmex.de</p>
            <p></p>
            <h3>Vertreten durch:</h3>
            <p>Vendula Novakova<br /></p>
            <p>Michelle Affolter<br /></p>
            <p>Hans-Jörg Kapp<br /></p>
            <p></p>
            <p><br /></p>
            <p></p>
            <h2>Hinweise zur Website</h2>
            <p></p>
            <h2>Information gemäß § 36 VSBG</h2>
            <p>Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die alternative Streitbeilegung in
              Verbrauchersachen) erklären die Betreiber*innen dieser Website:</p>
            <p>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.</p>
            <p></p>
            <h3>Gestaltung</h3>
            <p>David Benjamin Schulz<br /></p>

            <h3>Webentwicklung</h3>

            <p>Federico Ardila Biela<br /></p>


            <p><em>Das Impressum wurde mit dem <a
              href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der activeMind
              AG</a> erstellt.</em></p>


          </Col>
        </Row>

      </Container>
    )
}

export default Impressum;