import { Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Container, Row } from "reactstrap"
import { Collective, getCollectives, getMembers, Member } from "./services/Mitglieder"
import placeholderImg from './img/placeholder2.jpg'
import placeholderColl from './img/800x180.png'
import { Route, Router, Switch, useHistory } from "react-router-dom"
import MitgliedPage from "./MitgliedPage"
import React, { MouseEventHandler, useEffect } from "react"
import Kollektive from "./Kollektive"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"
import { firestore, storage } from "./firebase"
import trennlinie from './img/line-headline.svg'
import { useDownloadURL } from "react-firebase-hooks/storage"
import MitgliderCard from "./MitgliederCard"


export interface MitgliederParams {

}

function truncateString(str: string, num: number) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}



const Mitglieder: React.FC<MitgliederParams> = ({ }) => {

    const history = useHistory();
    const [members] = useCollectionDataOnce<Member>(firestore.collection("/sections/mitglieder/mitglieder").orderBy("lastname"))

    useEffect(()=>{
        console.log("rerendered mitglieder");


    },[members])

    
    
    const getCollectiveCard = (c: Collective) => {
    
        const onClick :MouseEventHandler<HTMLDivElement> = (e:any)=>{
            history.push(`/mitglieder/k/${c.id}`)
        }
    
        return (
    
            <Col sm="12">
                <Card onClick={onClick}>
                    <CardImg top src={placeholderColl}  alt="Card image cap" />
                    <CardBody>
                        <CardTitle tag="h5" >{c.name} </CardTitle>
                        <CardSubtitle>{c.extras}.</CardSubtitle>
                    </CardBody>
    
                </Card>
    
            </Col>
        )
    }
    

    return (<div>


                <Container  >
                    <Row className="mitglieder">
                        <Col sm="12">
                        <h1 id="sub-mitglieder" style={{marginBottom:"60px"}}> <img src={trennlinie} className="svg-icon svg-baseline " /> Mitglieder</h1>
                        </Col>

                        {members && members.sort((m1, m2)=> m1.lastname.localeCompare(m2.lastname)).map( m=> (<MitgliderCard key={m.id} id={m.id} lastname={m.lastname} picturePath={m.picturePath} name={m.name} positionList={m.positionList}  />) ) }

                    </Row>



                    {/* <Row className="kollektive">

                        <Col sm="12" >
                            <h1>Kollektive</h1>
                        </Col>


                        {getCollectives().map(getCollectiveCard)}

                    </Row> */}

                </Container>


        <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>



    </div>)
}

export default Mitglieder