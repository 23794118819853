import { Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Container, Row } from "reactstrap";
import { Calendar,  momentLocalizer, Views } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./Calendar.css"
import moment from 'moment'
import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import React from "react";
import placeholderImg from './img/800x180.png'
import { scrollToElm } from "./scrollHelper";
import { JsxElement } from "typescript";
import { Route, Switch, useHistory } from "react-router-dom";
import Event from"./Event";
import { StimmexEvent } from "./services/Events";
import trennlinie from './img/line-headline.svg';
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "./firebase";
import firebase from "firebase/app";
import { start } from "@popperjs/core";
import EventCard from "./EventCard";




moment.updateLocale("DE-de", null)



const  yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const SaveTheDate: React.FC = () => {


    

    const [events] = useCollectionDataOnce<StimmexEvent>(firestore.collection("/events").where("start", ">", yesterday).orderBy("start", "asc"))
    const [eventsByMonth, setEventsByMonth] = useState<{[month:string]: StimmexEvent[]}>();

    useEffect(()=>{
        if(events && events.length>0){
            setAvtive(true);
            
            const eventsByMonth = events.reduce((acc: { [month: string]: StimmexEvent[] }, event: StimmexEvent) => {
                const startTimestamp = event.start as firebase.firestore.Timestamp;
                if(event.start instanceof firebase.firestore.Timestamp){
                    const startTimestamp = event.start as firebase.firestore.Timestamp;
                    event.start = startTimestamp.toDate();
                }
                const month = event.start.toLocaleDateString("de-DE", { month: "long" });
                const year = event.start.toLocaleDateString("de-DE", { year: "numeric" });
                const key = `${month} ${year}`
                if (acc[key]) {
                    acc[key].push(event)
                } else {
                    acc[key] = [event]
                }
                return acc;
            }, {})
            setEventsByMonth(eventsByMonth)
        }
    }, [events])

    

    const [active, setAvtive] = useState(false);



    const getMonthElements = (groupedMoths: { [month: string]: StimmexEvent[] }) => {
        const result: JSX.Element[] = [];
        for (const month in groupedMoths) {
            const eventsInMonth = groupedMoths[month];
            const mothElement = (<MonthElement name={month} key={month} events={eventsInMonth} />)
            result.push(mothElement)
        }
        return result;
    }

    const MonthElement: React.FC<{ name: string, events: StimmexEvent[] }> = ({ name, events }) => {
        const defaultDate = new Date(events[0].start)

        const [eventsToShow, setEventsToShow] = useState(events);
        const [filterSet, setFilterSet] = useState(false);


        const onSelectEvent = (event: StimmexEvent) => {
            setEventsToShow(events.filter(d => d.start.getTime() == event.start.getTime()));
            setFilterSet(true);

        }

        const removeFilter = ()=>{
            setEventsToShow(events);
            setFilterSet(false);
        }



        return (
            <Row>
                <Col sm="12"><h1 onClick={removeFilter} style={{textAlign: "center"}}>{name}</h1><br /></Col>
                
                
                <Col sm="12">
                    <div style={{width: "300px", marginLeft:"auto", marginRight: "auto", marginBottom: "50px"}}>
                    <Calendar
                        style={{ height: "220px",  width: "100%" }}
                        events={events}
                        startAccessor="start"
                        endAccessor="start"
                        view="month"
                        views={["month"]}
                        toolbar={false}
                        onSelectEvent={onSelectEvent}
                        defaultDate={defaultDate}



                        components={{
                            timeSlotWrapper: ColoredDateCellWrapper
                        }}
                        localizer={localizer}
                    />

                    <Col sm="12">
                        {filterSet && 
                        <a style={{fontSize:"18px"}} href="#" onClick={removeFilter}>{"<<<< Alle Events anzeigen"}</a>
                        }
                        
                        
                        </Col>


                    </div>


                </Col>

                        {eventsToShow.map(event => <EventCard event={event} key={event.id} />)}


            </Row>

        )

    }


    const localizer = momentLocalizer(moment);
    const ColoredDateCellWrapper: any = ({ children }: { children: any }) =>
        React.cloneElement(React.Children.only(children), {
            style: {
                backgroundColor: 'red',
            },
        })


    return (
        <>
        {active && eventsByMonth &&

                <Container>

                    <Row className="events">
                        {getMonthElements(eventsByMonth)}
                    </Row>


                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                </Container>}

        {!active &&
            <Container>
                <Row>
                    <Col sm={12}>
                    <h1 id={`sub-save-the-date-placeholder`} className="subsection"> <img src={trennlinie} className="svg-icon " /> Save The Date</h1>

                    <p>Auf dieser Seite finden Sie in Zukunft alle unsere Termine und einen Veranstaltungskalender für Zeitgenössische Musiktheaterproduktionen in Norddeutschland.</p>
                    </Col>
                </Row>
            </Container>
        }

        </>

    )
}

export default SaveTheDate

