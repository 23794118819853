import { useDownloadURL } from "react-firebase-hooks/storage";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import { storage } from "./firebase";
import { Member } from "./services/Mitglieder";

const VorstandListElement: React.FC<{ member: Member, backPath?: string }> = ({ member, backPath }) => {

    const history = useHistory();
    const onClick = () => {
        history.push(`/verein/s/v/${member.id}`)
    }
    
    const [imageUrl] = useDownloadURL(
        storage.ref(member.picturePath)
    );

    return (
        <Col sm="12">
            <Card className="vorstand-card" onClick={onClick}>
                <Row noGutters>
                    <Col md="4">
                        <CardImg src={imageUrl} style={{ height:"290px", objectFit: "cover" }} />

                    </Col>

                    <Col md="8">
                        <CardBody>
                            <CardSubtitle>{member.role}</CardSubtitle>
                            <CardTitle tag="h2">{member.name} {member.lastname}</CardTitle>
                            <p>&bdquo;{member.zitat}&ldquo;</p>

                            <p>{member.email}</p>
                        </CardBody>
                    </Col>

                </Row>


            </Card>

        </Col>


    )
}

export default VorstandListElement;