import React, { useEffect } from "react";
import CSS from 'csstype';
import { v4 as uuidv4 } from 'uuid';

const TopTriabgle: React.FC<{ style: React.CSSProperties, opacity:string, id?:string}> = ({ style, opacity, id }) => {




  return (
    <svg
    id={id}

      style={style}

      

      xmlns="http://www.w3.org/2000/svg"

      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 12 21.67"
    >
      <defs>


        <pattern id="diagonalHatch2" width=".5" height=".5" patternTransform="rotate(60 0 0)" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2=".5" stroke="#487970" strokeWidth=".1"/>
        </pattern>





      </defs>
      <g
        fill="#fff"
        fillOpacity="1"
        stroke="#fff"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="0"
        strokeWidth="1"
        opacity="1"
      >
        <path
          // filter="url(#dropshadow)"
          fill="#fff"
          fillOpacity={opacity}
          stroke="#000"

          strokeDasharray="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="0"
          strokeWidth=".1"

          d={`
          M 0, 0
          l 10 , 21.68
          h 2
          v -21.68
          z
          `}
        ></path>


      </g>
    </svg>
  );
}

export default TopTriabgle;
